import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createUUID } from '../../utils/createUUID';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { BlockCard } from '../../components/container';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../components/common/DxTable';
import {
	Button,
	ButtonCancel,
	ButtonLink,
	ButtonSave,
	LoadingTable,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
} from '../../components/base';
import { BannerConfigModel } from '../../context/models/banners/BannerConfigModel';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { useLookupBannerPosition } from '../../components/shared/hoc-banner-position/useLookupBannerPosition';
import { useLookupProductCategory } from '../../components/shared/hoc-product-category/useLookupProductCategory';
import { useForm } from 'react-hook-form';

type IBannerConfigDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: BannerConfigModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: BannerConfigModel) => void;
};
const BannerConfigModal = (props: IBannerConfigDetailModalProps) => {
	const dispatch = useDispatch();

	const { isShow, title, onSubmit, onClose, isSaving } = props;
	// const isAddMode = !detailModel;

	const { status, allProductCategorys, selectedIds } = useSelector((state: AppState) => state.productCategory);

	const dataSources = useMemo(() => {
		return allProductCategorys.sort((a, b) => a.id - b.id);
	}, [allProductCategorys]);

	const { handleSubmit } = useForm<BannerConfigModel>({});

	const onSubmitHandler = (values: BannerConfigModel) => {
		const data = { ...values };
		onSubmit(data);
	};

	const handleReloadDataWithFilter = useCallback(
		() => {
			dispatch(actions.productCategory.getAllRequest());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const { product_category_all } = useLookupProductCategory();

	const onChangeSelectedIds = (ids: number[]) => {
		dispatch(actions.productCategory.changeSelectedIds(ids));
	};

	useEffect(() => {
		handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status == eBaseActionStatus.reload) handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='id' caption={`Trang hiển thị`} minWidth={150} fixed>
				<DxLookup dataSource={product_category_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
		];
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product_category_all]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='md' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<div className='col-sm-12'>
						<BlockCard>
							{status === eBaseActionStatus.loading && <LoadingTable />}
							{status !== eBaseActionStatus.loading && (
								<DxTable
									dataSource={dataSources}
									keyExpr='id'
									columns={columns}
									hasFixed={true}
									isLoading={status !== eBaseActionStatus.complete}
									defaultPageSize={50}
									height={window.innerHeight - 300}
									wordWrapEnabled={true}
									selection={{
										mode: 'multiple',
										onSelectionChanged: (e: any) => {
											onChangeSelectedIds(e.selectedRowKeys || []);
										},
										selectedRowKeys: selectedIds,
									}}
								/>
							)}
						</BlockCard>
					</div>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave type='button' isLoading={isSaving} isDisabled={isSaving} onClick={handleSubmit(onSubmitHandler)} />
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default BannerConfigModal;
