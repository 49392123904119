import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { AppState } from '../../../context/stores/rootReducers';
import { useLocation, useNavigate } from 'react-router-dom';

type IFooterTableProps = {
	titlePage: string;
	status: eBaseActionStatus;
	selectedIds: number[];
	showDetail: BaseShowDetail<any>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: any) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<any>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
	
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({

});

const FooterTable = (props: IFooterTableProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		titlePage = 'Cấu hình Footer',
		status,
		selectedIds,
		showConfirm,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		onShowConfirm,
	} = props;

	const [allLinks, setAllLinks] = useState([
		{
			id: 1,
			category: { name: 'Liên hệ', link: '/contact', icon: '' },
			subcategory: { name: 'Email', link: '/contact/email', icon: '' },
			inActive: false,
		},
		{
			id: 2,
			category: { name: 'Giới thiệu', link: '/about', icon: '' },
			subcategory: { name: 'Tầm nhìn', link: '/about/vision', icon: '' },
			inActive: true,
		},
		{
			id: 3,
			category: { name: 'Hỗ trợ', link: '/support', icon: '' },
			subcategory: { name: 'FAQ', link: '/support/faq', icon: '' },
			inActive: false,
		}
	]);

	const handleAddEditAction = (id?: string) => {
		navigate(`${location.pathname}/edit/${id || '0'}`, { replace: false });
	};

	useEffect(() => {
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = useMemo((): any => {
		return [
			<DxTableColumn
				key={createUUID()}
				dataField='category'
				caption='Category'
				minWidth={300}
				cellRender={(cell: any) => {
					const category = cell.data.category;
					return (
						<div className='user-card'>
							<div className='user-info'>
								<p className='tb-sub p-0 m-0 pb-1'>
									Tên: <b>{category.name}</b>
								</p>
								<p className='tb-sub p-0 m-0 pb-1'>
									Link: <b>{category.link}</b>
								</p>
								{category.icon && (
									<p className='tb-sub p-0 m-0 pb-1'>
										Icon: <img src={category.icon} alt='icon' width='20' height='20' />
									</p>
								)}
							</div>
						</div>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='subcategory'
				caption='Subcategory'
				minWidth={300}
				cellRender={(cell: any) => {
					const subcategory = cell.data.subcategory;
					return (
						<div className='user-card'>
							<div className='user-info'>
								<p className='tb-sub p-0 m-0 pb-1'>
									Tên: <b>{subcategory.name}</b>
								</p>
								<p className='tb-sub p-0 m-0 pb-1'>
									Link: <b>{subcategory.link}</b>
								</p>
								{subcategory.icon && (
									<p className='tb-sub p-0 m-0 pb-1'>
										Icon: <img src={subcategory.icon} alt='icon' width='20' height='20' />
									</p>
								)}
							</div>
						</div>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='inActive'
				minWidth={80}
				caption='Trạng thái'
				falseText='Áp dụng'
				trueText='Không'
			></DxTableColumn>,
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption='Sửa'
				alignment='center'
				allowExporting={true}
				cellRender={(cell: any) => {
					return <ButtonLink onClick={() => handleAddEditAction(cell.data.id)} title='Sửa' icon='ni ni-edit' theme='link' />;
				}}
			/>
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds?.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds?.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => handleAddEditAction()}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allLinks}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showConfirm && (
				<ConfirmModal
					show={showConfirm}
					innerText={`Bạn chắc chắn muốn xóa ${selectedIds?.length} ${titlePage.toLowerCase()} đã chọn ?`}
					type='danger'
					onClose={() => onShowConfirm(false)}
					onConfirmed={() => handleDeleteData(selectedIds)}
					isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
					btnConfirmText='Xóa'
					btnCloseText='Không xóa'
				/>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterTable);
