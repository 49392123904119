import React, { useEffect, useState } from 'react';
import {
	ButtonCancel,
	ButtonSave,
	BaseForm,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { slugHelper } from '../../utils/slugHelper';
import { CollectionModel, dfCollectionModel, validCollectionSchema } from '../../context/models/collections/CollectionModel';
import { ImageBox } from '../../components/base/ImageBox';
import ComboBoxCollectionGroup from '../../components/shared/hoc-collection-group/ComboBoxCollectionGroup';

type ICollectionDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: CollectionModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: CollectionModel) => void;
};

const CollectionDetail = (props: ICollectionDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<CollectionModel>(dfCollectionModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<CollectionModel>({
		//@ts-ignore
		resolver: yupResolver(validCollectionSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: CollectionModel) => {
		onSubmit(values);
	};

	const watchChangeName = useWatch({ control, name: 'name', defaultValue: '' });

	useEffect(() => {
		const slug = slugHelper.toSlug(watchChangeName);
		setValue('slug', slug);
		setInitialValues((prev) => ({ ...prev, slug }));
	}, [watchChangeName, setValue]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<ComboBoxCollectionGroup
							name={'collectionGroupId'}
							error={errors.collectionGroupId?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, collectionGroupId: value }));
							}}
							value={initialValues.collectionGroupId}
							hasValid={true}
							label='Vị trí'
						/>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên chuyên mục'
						/>
						<TextBox
							name={'slug'}
							error={errors.slug?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, slug: value }));
							}}
							value={initialValues.slug}
							hasValid={true}
							label='Slug'
							helpBlock={`Link truy cập nội dung: <code>ten-chuyen-muc</code>. Hoặc nhập tên vào hệ thống sẽ tự tạo.`}
						/>

						<TextBox
							name={'description'}
							error={errors.description?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, description: value }));
							}}
							value={initialValues.description}
							hasValid={true}
							label='Mô tả'
						/>
						<ImageBox
							name='image'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.image}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									image: image?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh'
						/>
						<ImageBox
							name='icon'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.icon}
							onDataChanged={(icon) => {
								setInitialValues((prev) => ({
									...prev,
									icon: icon?.path || '',
								}));
							}}
							hasValid={true}
							label='Icon'
						/>
						<TextBox
							name={'link'}
							error={errors.link?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, link: value }));
							}}
							value={initialValues.link}
							hasValid={true}
							label='Link'
						/>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
						{/* <SwitchBox
							name='isDelete'
							label='Áp dụng'
							error={errors.isDelete?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, isDelete: value }));
							}}
							value={initialValues.isDelete}
							sizeClass='sm'
						/> */}
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default CollectionDetail;
