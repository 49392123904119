import { ApiBase } from '../../base/_ApiBase';
import { ApiClient } from '../../base/_ApiClient';
import { CategoryModel } from '../../models/category/CategoryModel';
import { CategoryRequest } from '../../models/category/CategoryRequest';

export const CategoryApi = new (class CategoryApi extends ApiBase<CategoryModel> {
	constructor({ baseUrl }: { baseUrl: string }) {
		super(baseUrl);
	}

	CreateOrUpdateAsync(request: CategoryRequest) {
		return ApiClient.POST(`/ads-product-desc-category`, request);
	}

	SelectPaginationAsync(request: any) {
		const params = new URLSearchParams(request).toString();
		return ApiClient.GET(`/ads-product-desc-category${request ? `?${params}` : ''}`);
	}
})({ baseUrl: '/ads-product-desc-category' });
