import { ICategoryState, dfCategoryState } from './ICategoryState';
import { CategoryActionTypes } from './ICategoryActionTypes';
import { AnyAction } from 'redux';

const categoryReducer = (state = dfCategoryState, action: AnyAction): ICategoryState => {
	switch (action.type) {
		case CategoryActionTypes.CREATE_OR_UPDATE_REQUEST:
		case CategoryActionTypes.FETCH_PAGINATION_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case CategoryActionTypes.CREATE_OR_UPDATE_SUCCESS:
			return {
				...state,
				loading: false,
				categories: [...state.categories, action.payload],
			};
		case CategoryActionTypes.FETCH_PAGINATION_SUCCESS:
			return {
				...state,
				loading: false,
				categories: action.payload,
			};
		case CategoryActionTypes.CREATE_OR_UPDATE_FAILURE:
		case CategoryActionTypes.FETCH_PAGINATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default categoryReducer;
