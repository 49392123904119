import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import { BaseForm, ButtonSave, TextBox } from '../../../components/base';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { CategoryModel } from '../../../context/models/category/CategoryModel';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';

type ICategoryEditPageProps = {
	status: eBaseActionStatus;
	categoryDetail?: CategoryModel;
	handleGetDetail: (id: number) => void;
	handleSaveCategory: (data: CategoryModel) => void;
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	handleSaveCategory: (data: CategoryModel) => {
		dispatch(actions.category.createOrUpdateRequest(data));
	},
});

// Validation schema cho form
const validCategorySchema = Yup.object().shape({
	title: Yup.string().required('Vui lòng nhập tiêu đề.'),
});

const CategoryEditPage = (props: ICategoryEditPageProps) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const categoryId = useMemo(() => parseInt(id || '0'), [id]);

	const { handleGetDetail, handleSaveCategory, categoryDetail, status } = props;

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
		reset,
	} = useForm<CategoryModel>({
		mode: 'onChange',
		defaultValues: { id: '', title: '' },
	});

	// Load chi tiết của category nếu có ID
	useEffect(() => {
		if (categoryId > 0) {
			handleGetDetail(categoryId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	// Đặt giá trị ban đầu cho form khi có chi tiết category
	useEffect(() => {
		if (categoryDetail) {
			reset(categoryDetail);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryDetail]);

	// Xử lý submit form
	const onSubmitHandler = (values: CategoryModel) => {
		const request: CategoryModel = {
			...values,
			...(categoryId > 0 && { id: categoryId }),
		};

		handleSaveCategory(request);
	};

	// Điều hướng về trang danh sách khi lưu thành công
	useEffect(() => {
		if (status === eBaseActionStatus.complete) {
			navigate('/categories');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<PageContainer>
				<PageHeader title={categoryId > 0 ? 'Chỉnh sửa Category' : 'Tạo mới Category'}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
								<ButtonSave type='button' onClick={handleSubmit(onSubmitHandler)} />
							</BaseForm>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
							<div className='row g-4'>
								<div className='col-xl-12 col-sm-12'>
									<div className='nk-block g-4'>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='name'>Tiêu đề</h6>
											</div>
											<div className='col-9'>
												<Controller
													name='title'
													control={control}
													render={({ field }) => (
														<TextBox
															{...field}
															value={field.value}
															error={errors.title?.message}
															onValueChanged={(value) => field.onChange(value)}
														/>
													)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</BaseForm>
					</BlockCard>
				</PageBody>
			</PageContainer>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEditPage);
