import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { CategoryModel } from '../../models/category/CategoryModel';
import { CategoryRequest } from '../../models/category/CategoryRequest';

// Enum for Category actions
export enum CategoryActionTypes {
  CREATE_OR_UPDATE_REQUEST = 'Category_CREATE_OR_UPDATE_REQUEST',
  CREATE_OR_UPDATE_SUCCESS = 'Category_CREATE_OR_UPDATE_SUCCESS',
  CREATE_OR_UPDATE_FAILURE = 'Category_CREATE_OR_UPDATE_FAILURE',
  FETCH_PAGINATION_REQUEST = 'Category_FETCH_PAGINATION_REQUEST',
  FETCH_PAGINATION_SUCCESS = 'Category_FETCH_PAGINATION_SUCCESS',
  FETCH_PAGINATION_FAILURE = 'Category_FETCH_PAGINATION_FAILURE',
}

// Interface for Create or Update Category Request
export interface CategoryAction_CreateOrUpdate_Request
  extends IActionTypeBase<CategoryActionTypes.CREATE_OR_UPDATE_REQUEST, CategoryRequest> {}

// Interface for Create or Update Category Success
export interface CategoryAction_CreateOrUpdate_Success
  extends IActionTypeBase<CategoryActionTypes.CREATE_OR_UPDATE_SUCCESS, CategoryModel> {}

// Interface for Create or Update Category Failure
export interface CategoryAction_CreateOrUpdate_Failure
  extends IActionTypeBase<CategoryActionTypes.CREATE_OR_UPDATE_FAILURE, string> {}

// Interface for Fetch Category Pagination Request
export interface CategoryAction_FetchPagination_Request
  extends IActionTypeBase<CategoryActionTypes.FETCH_PAGINATION_REQUEST, BaseSelectRequest> {}

// Interface for Fetch Category Pagination Success
export interface CategoryAction_FetchPagination_Success
  extends IActionTypeBase<CategoryActionTypes.FETCH_PAGINATION_SUCCESS, BasePaginationRespone<CategoryModel>> {}

// Interface for Fetch Category Pagination Failure
export interface CategoryAction_FetchPagination_Failure
  extends IActionTypeBase<CategoryActionTypes.FETCH_PAGINATION_FAILURE, string> {}

// Union type for all Category actions
export type CategoryActionTypesUnion =
  | CategoryAction_CreateOrUpdate_Request
  | CategoryAction_CreateOrUpdate_Success
  | CategoryAction_CreateOrUpdate_Failure
  | CategoryAction_FetchPagination_Request
  | CategoryAction_FetchPagination_Success
  | CategoryAction_FetchPagination_Failure;
