export type BaseSelectRequest = {
    searchString: string;
    pageIndex: number;
    pageSize: number;
}

export const defaultBaseSelectRequest : BaseSelectRequest = {
    searchString: '',
    pageSize: 20,
    pageIndex: 1
} 