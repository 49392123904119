import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';
import { dfKeywordModel, KeywordModel, validKeywordSchema } from '../../context/models/keyword/KeywordModel';
import { slugHelper } from '../../utils/slugHelper';
import ComboBoxProductCategory from '../../components/shared/hoc-product-category/ComboBoxProductCategory';

type IKeywordDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: KeywordModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: KeywordModel) => void;
};

const KeywordDetail = (props: IKeywordDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<KeywordModel>(dfKeywordModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<KeywordModel>({
		//@ts-ignore
		resolver: yupResolver(validKeywordSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: KeywordModel) => {
		onSubmit(values);
	};

	const watchChangeName = useWatch({ control, name: 'name', defaultValue: '' });

	useEffect(() => {
		const slug = slugHelper.toSlug(watchChangeName);
		setValue('slug', slug);
		setInitialValues((prev) => ({ ...prev, slug }));
	}, [watchChangeName, setValue]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<ComboBoxProductCategory
							name={'catId'}
							error={errors.catId?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, catId: value }));
							}}
							value={initialValues.catId}
							hasValid={true}
							label='Tên từ khóa sản phẩm'
						/>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên từ khóa'
						/>
						<TextBox
							name={'slug'}
							error={errors.slug?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, slug: value }));
							}}
							value={initialValues.slug}
							hasValid={true}
							label='Slug'
							helpBlock={`Link truy cập nội dung: <code>ten-chuyen-muc</code>. Hoặc nhập tên vào hệ thống sẽ tự tạo.`}
						/>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default KeywordDetail;
