import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { BaseForm, ButtonSave, DxDateBox, LoadingPanel, NumberBox, SwitchBox, TextAreaBox, TextBox } from '../../../components/base';
import { ImageBox } from '../../../components/base/ImageBox';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { history } from '../../../utils/history';
import { BannerModel, dfBannerModel, validBannerSchema } from '../../../context/models/banners/BannerModel';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { BannerActionTypes } from '../../../context/stores/banner/IBannerActionsTypes';
import { useDispatch } from 'react-redux';
import BannerConfigTable from '../../banner-config';
import { BannerConfigModel } from '../../../context/models/banners/BannerConfigModel';
import { dfBannerPositionModel } from '../../../context/models/banners/BannerPositionModel';

const mapStateToProps = (state: AppState) => {
	return {
		status: state.banner.status,
		allBanners: state.banner.allBanners,
		detailResponse: state.banner.detailResponse,
		selectedIds: state.banner.selectedIds,
		showDetail: state.banner.showDetail,
		showConfirm: state.banner.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<BannerActionTypes>) => ({
	handleGetDetail: (id: number) => {
		dispatch(actions.banner.detailRequest(id));
	},
	handleReloadAllData: () => {
		dispatch(actions.banner.getAllRequest());
	},
	handleSaveData: (data: BannerModel) => {
		dispatch(actions.banner.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.banner.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.banner.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<BannerModel>) => {
		dispatch(actions.banner.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.banner.showConfirm(isShow));
	},
});

type IBannerEditPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allBanners: BannerModel[];
	selectedIds: number[];
	detailResponse?: BannerModel;
	showDetail: BaseShowDetail<BannerModel>;
	showConfirm: boolean;
	isSaving: boolean | false;
	handleReloadAllData: () => void;
	handleGetDetail: (id: number) => void;
	handleSaveData: (data: BannerModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<BannerModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const BannerEditPage = (props: IBannerEditPageProps) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const bannerId = useMemo(() => parseInt(id || '0'), [id]);
	const positionId = useMemo(() => parseInt(id || '0'), [id]);
	const listPageId = useMemo(() => parseInt(id || '0'), [id]);
	const navigate = useNavigate();

	const { status, detailResponse, handleGetDetail, handleReloadAllData, handleSaveData, isSaving } = props;

	console.log('detailResponse', detailResponse);
	const [initialValues, setInitialValues] = useState<BannerModel>(dfBannerModel);
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<BannerModel>({
		//@ts-ignore
		resolver: yupResolver(validBannerSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (detailResponse) {
			setInitialValues((prev) => ({
				...prev,
				...detailResponse,
			}));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailResponse]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (bannerId > 0) {
			handleGetDetail(bannerId);
		} else {
			setInitialValues(dfBannerModel);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerId]);

	const onSubmitHandler = (values: BannerModel) => {
		handleSaveData({ ...values });
	};

	useEffect(() => {
		if (bannerId > 0) {
			handleGetDetail(bannerId);
		} else {
			setInitialValues(dfBannerModel);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerId]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) navigate(history.location?.pathname || '/', { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Thông tin Banner`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
								<ButtonSave type='button' isLoading={isSaving} isDisabled={isSaving} onClick={handleSubmit(onSubmitHandler)} />
							</BaseForm>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingPanel />}
						<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
							<div className='row g-4'>
								<div className='col-xl-9 col-sm-12'>
									<div className='nk-block g-4'>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='name'>Tên Banner</h6>
											</div>
											<div className='col-9'>
												<TextBox
													name='name'
													value={initialValues.name}
													error={errors.name?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, name: value }));
													}}
												/>
											</div>
										</div>
										<div className='row g-5'>
											<div className='flex flex-row align-center'>
												<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='titleSlug'>Banner</h6>
												</div>
												<div className='col-9'>
													<ImageBox
														name='path'
														isDisabled={initialValues.name.length <= 0}
														file_name={initialValues.name}
														value={initialValues.path}
														onDataChanged={(image) => {
															setInitialValues((prev) => ({
																...prev,
																path: image?.path || '',
																extension: image?.extension || '',
																size: image?.size || '',
															}));
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='nk-block'>
										<div className='col g-5'>
											<div className='flex flex-row align-center'>
												<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='priceOnSale'>Kích thước</h6>
												</div>
												<div className='col-4'>
													<NumberBox
														name='width'
														value={initialValues.width}
														error={errors.width?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, width: value }));
														}}
														sizeClass='sm'
														isDisabled={true}
														helpBlock='Chiều rộng Banner'
													/>
													<NumberBox
														name='height'
														value={initialValues.height}
														error={errors.height?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, height: value }));
														}}
														sizeClass='sm'
														isDisabled={true}
														helpBlock='Chiều cao Banner'
													/>
												</div>
											</div>
											<div className='flex flex-row align-center'>
												<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
													<h6 className='catId'>Link</h6>
												</div>
												<div className='col-9'>
													<TextBox
														name='link'
														value={initialValues.link}
														error={errors.link?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, link: value }));
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='nk-block col g-4'>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='name'>Tiêu đề</h6>
											</div>
											<div className='col-9'>
												<TextAreaBox
													name='title'
													value={initialValues.title}
													error={errors.title?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, title: value }));
													}}
													className='form-control-simple no-resize editor-focus editor-f-18'
													classWrapper='border-bottom'
													// sizeClass='sm'
													placeholder='Viết gì đó...'
												/>
											</div>
										</div>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='initContent'>Mô tả</h6>
											</div>
											<div className='col-9'>
												<TextAreaBox
													name='description'
													value={initialValues.description}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, description: value }));
													}}
													className='form-control-simple no-resize editor-focus editor-f-16'
													classWrapper='border-bottom'
													// sizeClass='sm'
													placeholder='Viết gì đó...'
												/>
											</div>
										</div>
									</div>
									<div className='flex flex-row align-center pt-5'>
										<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='contentHtml'>Thiết lập vị trí hiển thị</h6>
										</div>
										<div className='col-9'>
											<BannerConfigTable bannerId={bannerId} positionId={positionId} listPageId={listPageId} />
										</div>
									</div>
								</div>
								<div className='col-xl-3'>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Ngày đăng tải</h6>
										</div>
										<DxDateBox
											name='beginDate'
											value={moment(initialValues.beginDate).format()}
											displayFormat={'dd/MM/yyyy HH:mm'}
											typeDate='datetime'
											onValueChanged={(e) => {
												setInitialValues((prev) => ({ ...prev, beginDate: e.value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Ngày hết hạn</h6>
										</div>
										<DxDateBox
											name='endDate'
											value={moment(initialValues.endDate).format()}
											displayFormat={'dd/MM/yyyy HH:mm'}
											typeDate='datetime'
											onValueChanged={(e) => {
												setInitialValues((prev) => ({ ...prev, endDate: e.value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Trạng thái</h6>
										</div>
										<SwitchBox
											name='inActive'
											label='Áp dụng'
											error={errors.inActive?.message}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, inActive: value }));
											}}
											value={initialValues.inActive}
											sizeClass='sm'
										/>
									</div>
								</div>
							</div>
						</BaseForm>
					</BlockCard>
				</PageBody>
			</PageContainer>
			{/* {showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={handleSubmit(onSubmitHandler)}
						isLoading={status === eBaseActionStatus.loading}
						mesageText={`Bạn chắc chắc muôn ${getBannerStatusName(showConfirm.actionStatus)} sản phẩm này?`}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)} */}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerEditPage);
